import React from "react"
import { Box, Typography } from "@mui/material"
import { useLocation, useRouteError } from "react-router-dom"
import NotFound from "./NotFound"

interface ErrorWithStatus extends Error {
  status?: number
}

const RouterError: React.FC = () => {
  const location = useLocation()
  const error = useRouteError() as ErrorWithStatus

  if (error.status === 404) return <NotFound />

  if (error.message.includes("Failed to fetch dynamically imported module")) {
    window.location.href = `${location.pathname}${location.search}`
    window.location.reload()

    return null
  }

  return (
    <Box
      sx={{
        textAlign: "center",
        pt: 20,
      }}
    >
      <Typography variant="h4">Oops - an error has occurred!</Typography>
    </Box>
  )
}

export default RouterError
